import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import { Link } from 'react-router-dom'
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import { useTranslation } from 'react-i18next'
import CboxMenuList from './cbox-menu-list'

const useStyles = makeStyles(theme => ({
  appbar: {
    position: 'fixed',
    backgroundColor: 'whitesmoke',
    color: 'rgb(0,152,210)',
  },
  appIconLeft: {
    color: 'rgb(0,152,210)',
  },
  profileIcon: {
    height: 32,
    width: 32,
  },
  avatar: {
    height: 32,
    width: 32,
    float: 'left',
  },
  editIcon: {
  },
  editIconLoggedIn: {
    marginTop: -10,
  },
  logo: {
    marginTop: 10,
    height: 46,
  },
  logoSmall: {
    paddingTop: 5,
    height: 35,
  },
  toolbar: {
  },
  toolbarSmall: {
    height: 36,
    minHeight: 36,
  },
  title: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 30,
    textDecoration: 'none',
    flexBasis: '100%',
  },
  root: {
    width: '100%',
  },
  filler: {
  },
  rightMenuLoggedIn: {
    marginRight: -10,
    width: 40,
    clear: 'both',
  },
  rightMenu: {
    marginTop: -3,
    marginRight: -10,
    width: 40,
    clear: 'both',
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -22,
  },
}))

const LoginMenu = (props) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)

  const handleMenuClick = (menuItem) => {
    if (props.onRightIconMenuSelect!=null){
      props.onRightIconMenuSelect(menuItem)
    }
    setOpen(false)
  }
// color='rgb(0,152,210)'
  return (
    <div
      open={this.state.open}
      onRequestChange={(open) => this.setState({open})}
      targetOrigin={{horizontal: 'right', vertical: 'top'}}
      anchorOrigin={{horizontal: 'right', vertical: 'top'}}
    >
      <CboxMenuList
        hideIcons={true}
        title={props.title}
        menuMode="settings"
        onRequestClose={handleClose()}
        onMenuClick={(m) => handleMenuClick(m)}
      />
    </div>
  )
}
LoginMenu.muiName = 'IconMenu'

/*
<CboxMenuList
  hideIcons={true}
  title={this.props.title}
  menuMode="settings"
  onRequestClose={this.handleClose}
  onMenuClick={this.handleMenuClick}
/>
*/

const CBoxAppBar = (props) =>  {
  const { channel, user, mediaEdit, displayMenu, versionStr, largeScreen } = props
  const { t } = useTranslation()
  let useVersionStr
  if (versionStr) useVersionStr = t("version") + " " +versionStr

  const classes = useStyles()
  let tmpTitle = <div className={classes.title}><img src={process.env.PUBLIC_URL + '/icon/Qombi.png'} alt="" className={classes.logo} /></div>
  if (channel!=null){
    tmpTitle = <span className={classes.title}>{channel.title}</span>
  }
/*

ToDo: Idea - have the GraphQL server send some indication (some GraphQL structure)
whether the alternative to display the login and edit button is valid or not


    var displayName = user.displayName
    var email = user.email
    var emailVerified = user.emailVerified
    var isAnonymous = user.isAnonymous
    var uid = user.uid
    var providerData = user.providerData
*/
  return (
  <AppBar
    className={classes.appbar}
  >
    <Toolbar
      className={largeScreen ? classes.toolBar : classes.toolbarSmall }
    >
      <Typography
        className={classes.title}
        type="title"
        color="inherit"
      >
        <span className={classes.title}>
          <img
            src={process.env.PUBLIC_URL + '/icon/ConnectBox.png'}
            alt=""
            className={largeScreen ? classes.logo : classes.logoSmall} />
        </span>
      </Typography>
      <div className={classes.filler}></div>
      <div className={user? classes.rightMenuLoggedIn : classes.rightMenu}>
        {user?(
          <Avatar
            alt={user.displayName}
            src={user.photoURL}
            className={classes.avatar}
            onClick={props.onRightIconMenuSelect}
          />
        ):(
          <IconButton
            color='primary'
            aria-label="Login Menu"
            className={classes.profileIcon}
            onClick={props.onRightIconMenuSelect}
          >
            <PersonIcon/>
          </IconButton>
        )}
      </div>
    </Toolbar>
    {versionStr && (<Typography
      className={classes.version}
      type="title"
      color="inherit"
    >{useVersionStr}
    </Typography>)}
  </AppBar>
  )
}

export default CBoxAppBar
