import firebase from 'firebase'

// Initialize Firebase
export const config = {
  apiKey: "AIzaSyAS3v-0g8xlUHbya3-o3CHXx2usLrCG7Fk",
//  authDomain: "qombi-media.firebaseapp.com",
  authDomain: "qombi.com",
  databaseURL: "https://qombi-media.firebaseio.com",
  projectId: "qombi-media",
  storageBucket: "qombi-media.appspot.com",
  messagingSenderId: "302774017283"
}
firebase.initializeApp(config)

const CLIENT_ID = '302774017283-n2gpq0cpee2mjfuuimm7bbqhabkb3i47.apps.googleusercontent.com'

export default firebase

export const database = firebase.database()
export const auth = firebase.auth()
export const storage = firebase.storage()
export const storageRef = firebase.storage().ref()

export const provider_g = new firebase.auth.GoogleAuthProvider()
export const provider_fb = new firebase.auth.FacebookAuthProvider()
