import React from 'react'
import { apiGetConfig, apiSetConfig,
          getIdFromItem,
            apiSetStorage,
              apiObjGetStorage, apiObjSetStorage } from "../utils/api"
import CboxApp from '../components/cbox-app'
import { unique } from 'shorthash'
import localforage from 'localforage'
import { iso639_3b2 } from '../iso639-3b2'
import { auth } from '../utils/firebase'
import { uniqueArray } from '../utils/obj-functions'

const lang = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage)

const publishedObj = {
  pubTree: {}
}

export default class CboxAppContainer extends React.Component {
  state = {
    langList: [],
    titleList: [],
    myLang: [],
    myTitles: {},
    settings: {},
    channel: {title: "Test"},
    mediaEdit: false,
    defaultLang: undefined,
    percentList: -1,
    progressTextList: "",
    percentDownload: -1,
    progressTextDownload: "",
    curPlay: undefined,
    curPos: 0,
    curDur: 0,
    cur: undefined,
    curView: undefined,
    downloadReady: false,
    missingList: [],
    resetDownloadList: [],
    loadingText: "",
    loading: true,
    user: null,
  }


  componentDidMount() {
    const that = this
    let defaultLang = "eng" // Default
    if ((lang!=null)&&(lang.length>=2)){
      defaultLang = iso639_3b2[lang.substr(0,2)]
      if (defaultLang==null){
        defaultLang="eng" // Default
      }
console.log(defaultLang)
    }
    this.setState({defaultLang})
    auth.onAuthStateChanged((user) => {
      that.setState({user})
console.log(user)
    })
  }

  render() {
    const {loadingState, curView, user} = this.state
      return (
        <CboxApp
          loadingState={loadingState}
          curView={curView}
          user={user}
          onUpdateGUID={this.handleUpdateGUID}
          onUpdate={this.handleUpdate}
        />
    )
  }
}
