import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PersonIcon from '@material-ui/icons/Person'
import {auth, provider_g, provider_fb} from '../utils/firebase'
import {createCreds} from '../utils/webauthn'

const CboxUserMenu = props => {

  const handleLogin = (provider) => {
// Alternative for later
//   auth.signInWithRedirect(provider)
//   auth.signInWithRedirect(provider).then(function(result) {
   auth.signInWithPopup(provider).then(function(result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const token = result.credential.accessToken
      // The signed-in user info.
      const user = result.user
console.log(token)
console.log(user)
    }).catch(function(error) {
console.log(error)
      // Handle Errors here.
//      var errorCode = error.code
//      var errorMessage = error.message
      // The email of the user's account used.
//      var email = error.email
      // The firebase.auth.AuthCredential type that was used.
//      var credential = error.credential
      // ...
    })
    props.onMenuClick(null)
  }

  const handleLogout = () => {
    auth.signOut().then(function() {
//      window.location.assign('https://accounts.google.com/Logout')
    }).catch(function(error) {
      // An error happened.
    })
    props.onMenuClick(null)
  }

  const {user} = props
  if (user){
    return (
      <List>
        <ListItem
          button
          onClick={(e) => handleLogout()}
        >
          <ListItemIcon>
            <PersonIcon/>
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItem>
      </List>)
  } else {
    return (
      <List>
        <ListItem
          button
          onClick={(e) => handleLogin(provider_g)}
        >
          <ListItemIcon>
            <PersonIcon/>
          </ListItemIcon>
          <ListItemText primary="Sign in with Google" />
        </ListItem>
        <ListItem
          button
          onClick={(e) => handleLogin(provider_fb)}
        >
          <ListItemIcon>
            <PersonIcon/>
          </ListItemIcon>
          <ListItemText primary="Sign in with Facebook" />
        </ListItem>
        <ListItem
          button
          onClick={(e) => createCreds()}
        >
          <ListItemIcon>
            <PersonIcon/>
          </ListItemIcon>
          <ListItemText primary="Sign in with fingerprint reader" />
        </ListItem>
      </List>)
  }
}

export default CboxUserMenu
