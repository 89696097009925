import React, {useState} from 'react'
import CBoxAppBar from './cbox-app-bar'
import CboxMenuList from './cbox-menu-list'
import CboxIFrame from './cbox-iframe'
import Popover from '@material-ui/core/Popover'
import Drawer from '@material-ui/core/Drawer'
import CboxUserMenu from './cbox-user-menu'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const defaultBackgroundStyle = {
  marginTop: 50,
  minHeight: '100%',
  background: 'black'
}

const useStyles = makeStyles(theme => ({
  menuTitle: {
    margin: '15px 0px 4px 20px',
  },
  topButton: {
    margin: 10,
  },
  floatingButton: {
    margin: 0,
    bottom: 'auto',
    left: 20,
    top: 20,
    right: 'auto',
    zIndex: 100,
    position: 'fixed',
  },
  mainMessage: {
    marginLeft: 50,
    paddingTop: 90,
    maxWidth: 720,
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 55,
  },
  startNowButton: {
    marginTop: 25,
    marginLeft: 80,
  },
  navBackButton: {
    margin: 10,
    bottom: 'auto',
    left: 20,
    top: 20,
    right: 'auto',
  },
  configButton: {
    color: 'grey',
    marginLeft: 40,
    width: 100,
  },
  linearProgress: {
    width: '75%',
    marginTop: 150,
    marginLeft: '15%',
  },
  linearProgressDownload: {
    width: '75%',
    marginTop: 20,
    marginLeft: '15%',
  },
  progress: {
    margin: '150px 0px 0 250px',
  },
  textField: {
    marginLeft: 40,
    width: '70%',
    maxWidth: 400,
  },
  updateDiv:{
    marginBottom: 120,
  },
  mapLogo: {
    width: 70,
    height: 70,
    marginBottom: 15,
  },
  mapHeadline: {
    flex: 1,
  },
  chevronRight: {
  },
  resetAndDownloadButton: {
    marginTop: 10,
  },
  downloadButton: {
    marginLeft: 20,
    marginTop: 10,
  },
  updateButton: {
    width: 70,
    marginLeft: 20,
  },
  card: {
    width: '40%',
  },
  drawerPaperSmall: {
    width: '75%',
  },
  drawerPaperLarge: {
    width: 500,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  aboutTitle: {
    margin: '15px 0px 4px 50px',
  },
  aboutMainTitle: {
    paddingTop: 20,
    margin: '15px 0px 4px 50px',
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 25,
  },
  title: {
    cursor: 'pointer',
  },
  smallText: {
    position: 'absolute',
    right: 10,
    top: 70,
    fontSize: 10,
  },
}))

const lang = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage)

const CboxApp = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState()

  const classes = useStyles()
  let { curView, user } = props

  const handleToggle = () => setIsOpen(!isOpen)
  const handleClose = (item) => setIsOpen(false)
  const handleMenuClick = (item) => setIsOpen(false)

  const handleNavLang = (valArr) => {
console.log(valArr)
  }
  const handleUserMenu = (event) => {
console.log("user menu")
    setUserMenuOpen(true)
console.log(event.currentTarget)
    setAnchorEl(event.currentTarget)
  }
  const handleUserClose = () => {
    setUserMenuOpen(false)
    setAnchorEl(null)
  }
  return (
    <div
      id="page_container"
      style={user? defaultBackgroundStyle : null}>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={()=>handleUserClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CboxUserMenu
          user={user}
          onMenuClick={()=>handleUserClose()}
        />
      </Popover>
      <CBoxAppBar
        mediaEdit={false}
        user={user}
        onRightIconMenuSelect={(e)=>handleUserMenu(e)}
        onLeftIconButtonClick={()=>console.log("handleToggle")}
        onMediaEditUpdate={()=>console.log("onMediaEditUpdate")}
      />
      <CboxIFrame
        url={user ? "https://storage.googleapis.com/lib.qombi.com/img/MoodleScreenshot.png" : "http://demo.qombi.com/"}
        height="100%"
      />
    </div>
  )
}

export default CboxApp
